






















































































import { Component, Vue } from 'vue-property-decorator';
import { MaterialsContent } from '@/types/neuhaus-website/cms/pages/materials';
import axios, { APIResponse } from '@/plugins/axios';
import { handleFile } from '@/utils/cms/formhelper';
import AlertModule, { AlertType } from '@/store/modules/alert';
import { getFileObjectByUrl } from '@/utils/filehelper';

/**
 * CMSAdminMaterials component
 * 
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component({
    components: {
        'cms-page': () => import('@/components/cms/pages/CMSPage.vue'),
        'materials-expansion-panel-list': () => import('@/components/neuhaus-website/cms/admin/materials/MaterialsExpansionPanelList.vue')
    }
})
export default class CMSAdminMaterials extends Vue {
    // variable with form data
    private formData: MaterialsContent = {
        id: -1,
        title: '',
        expansionPanels: [],
        rightEdgeImage: {
            id: -1,
            src: '',
            obj: undefined
        },
        bottomImage: {
            id: -1,
            src: '',
            obj: undefined
        }
    }

    // Boolean that defines wheather form is valid or not
    private formValid = false;

    /**
     * Function will be executed on component load
     * Fetches data with fetchPageContent function
     */
    private async created() {
        this.formData = await this.fetchPageContent();

        // Fetch right edge image
        if (this.formData.rightEdgeImage.src) {
            const image = this.formData.rightEdgeImage;
            const imageUrl = process.env.VUE_APP_API_URL + image.src;
            const imageExtension = imageUrl.split('.').pop();
            const imageObj = await getFileObjectByUrl(
                imageUrl, 
                `materials-rightedgeimage-${this.formData.id}.${imageExtension}`
            );

            image.obj = imageObj;
        }

        // Fetch bottom image
        if (this.formData.bottomImage.src) {
            const image = this.formData.bottomImage;
            const imageUrl = process.env.VUE_APP_API_URL + image.src;
            const imageExtension = imageUrl.split('.').pop();
            const imageObj = await getFileObjectByUrl(
                imageUrl, 
                `materials-bottomimage-${this.formData.id}.${imageExtension}`
            );

            image.obj = imageObj;
        }
    }

    /**
     * Resets the form validation
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private resetFormValidation() {
        if (this.$refs.form) {
            (this.$refs.form as Vue & { resetValidation: () => void }).resetValidation();
        }
    }

    /**
     * Fetches page content from API
     * 
     * @returns MaterialsContent
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async fetchPageContent(): Promise<MaterialsContent> {
        try {
            const response = await axios.get<APIResponse<MaterialsContent>>('/cms/pages/materials')

            if (response.data.status === 'error' || !response.data.data) {
                throw null;
            }

            return response.data.data;
        } catch (err) {
            return this.formData;
        }
    }

    /**
     * Saves form data
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async saveFormData() {
        try {
            // Update right edge image
            const rightEdgeImageResult = await handleFile(
                undefined,
                this.formData.rightEdgeImage.src,
                this.formData.rightEdgeImage.obj
            );
            if (
                rightEdgeImageResult.data.status !== 'success' || 
                !rightEdgeImageResult.data.data.fileUrl
            ) {
                const errorMessage = this.$t('alerts.error.' + rightEdgeImageResult.data.message).toString();
                AlertModule.showAlert({ 
                    type: AlertType.ERROR,
                    message: errorMessage 
                });

                this.formData.rightEdgeImage.src = '';
            } else {
                this.formData.rightEdgeImage.src = rightEdgeImageResult.data.data.fileUrl;
            }

            // Update bottom image
            const bottomImageResult = await handleFile(
                undefined,
                this.formData.bottomImage.src,
                this.formData.bottomImage.obj
            );
            if (
                bottomImageResult.data.status !== 'success' || 
                !bottomImageResult.data.data.fileUrl
            ) {
                const errorMessage = this.$t('alerts.error.' + bottomImageResult.data.message).toString();
                AlertModule.showAlert({ 
                    type: AlertType.ERROR,
                    message: errorMessage 
                });

                this.formData.bottomImage.src = '';
            } else {
                this.formData.bottomImage.src = bottomImageResult.data.data.fileUrl;
            }
        } catch(err) {
            console.error(err); 
        }

        // Update page data
        axios.put<APIResponse>('/cms/pages/materials', this.formData)
            .then(response => {
                if (response.data.status === 'success' && response.data.data) {
                    this.resetFormValidation();
                    this.$router.push({ name: 'cmsAdminPages' });
                }
                else if (response.data.status === 'error' && response.data.message) {
                    const errorMessage = this.$t('alerts.errors.' + response.data.message).toString();
                    AlertModule.showAlert({ 
                        type: AlertType.ERROR,
                        message: errorMessage 
                    });
                }
            });
    }
}
