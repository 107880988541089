import axios, { APIResponse } from '@/plugins/axios';
import { Method as AxiosMethod, AxiosResponse } from 'axios';

/**
 * Handles file POST/PUT/DELETE
 *
 * @param dialogMode dialog/form mode (create, update, delete)
 * @param fileObj file object
 * @param fileSrc file uri
 *
 * @returns Promise<APIResponse>
 *
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
export async function handleFile(
    dialogMode: string | undefined, 
    fileSrc: string,
    fileObj: File | undefined
): Promise<AxiosResponse<APIResponse<{ fileUrl: string }>>> {
    // init new FormData obj
    const formData = new FormData();

    // set default values
    let fileApiUrl = '/cms/files/';
    let axiosMethod: AxiosMethod = 'POST';
    
    // set fileMode to dialogMode value
    let fileMode = dialogMode;

    // set fileMode to 'delete' when file src is set but obj is undefined
    if (fileSrc && !fileObj) {
        fileMode = 'delete';
    }

    // Handle dialogMode undefined
    if (fileMode === undefined) {
        if (!fileSrc && fileObj) {
            fileMode = 'create';
        }

        if (fileSrc && fileObj) {
            fileMode = 'update';
        }
    }

    // throw error if object is undefined and fileMode is NOT 'delete'
    if (!fileObj && fileMode !== 'delete') {
        throw 'fileObj is undefined';
    }

    // Switch through fileMode
    switch (fileMode) {
        case 'create':
            formData.append('file', fileObj || '');
            break;

        case 'update':
            formData.append('file', fileObj || '');
            fileApiUrl += fileSrc.split('/').slice(-1)[0];
            break;

        case 'delete':
            axiosMethod = 'DELETE';
            fileApiUrl += fileSrc.split('/').slice(-1)[0];
            break;
    }

    return axios({
        method: axiosMethod,
        url: fileApiUrl,
        data: formData,
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
}
